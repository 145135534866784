#user-input-form {
  > .ant-row {
    > .ant-form-item-label {
      // display: none;
    }
    > .ant-col {
      &.ant-form-item-label {
        label {
          width: 100% !important;
        }
      }

      > .ant-form-item-control-input {
        > .ant-form-item-control-input-content {
          > .input__field {
            width: 93% !important;
          }

          > .input__label {
            // width: 100% !important;
          }
        }
      }

    }
  }

  > fieldset.address {
    > div {
      // 郵便番号
      &:nth-child(3) {
        width: 122px !important;
        .input__label {
          width: 130px !important;
        }
      }

      // 都道府県、市区町村や郡
      &:nth-child(4) {
        > div {
          // 都道府県
          &:nth-child(1) {
            width: 98px !important;
          }

          // 市区町村や郡
          &:nth-child(2) {
            input {
              width: 91% !important;
            }
          }
        }

      }

      // 町名、番地
      &:nth-child(5) {
        > div {
          // 町名
          &:nth-child(1) {
            width: 122px !important;
          }

          // 番地
          &:nth-child(2) {
            .input__label {
              width: 100%;
            }
            input {
              width: 91% !important;
              margin-left: 8px;
              padding-right: 8px;
            }
          }
        }
      }

      // 建物名
      &:nth-child(6) {
        input {
          width: 93% !important;
        }
      }
    }
  }
}

#profile-input-form {
  > .ant-row {
    > .ant-form-item-label {
      // display: none;
    }

    > .ant-col {
      &.ant-form-item-label {
        label {
          width: 100% !important;
        }
      }

      > .ant-form-item-control-input {
        > .ant-form-item-control-input-content {
          > .input__field {
            width: 93% !important;
          }

          > .input__label {
            // width: 100% !important;
          }
        }
      }
    }
  }

  > div {
    // 口座種別
    &:nth-child(17) {
      // margin-top: 5em;
    }
  }
}
